<template>
    <div class="login-modal login-modal__close">
        <div class="login-banner">
            <img :src="banner" class="custom-banner" />
        </div>
        <!-- <div class="login-modal--head">
            <div>
                <a href="#"><img src="@/assets/images/logo.png" alt=""/></a>
                <a id="arabic" href="#">عربى</a>
            </div>
            <div class="login-modal--close">
                <a @click="$emit('closeLoginModal')">
                    Close
                    <svg width="24px" height="24px" fill="currentColor">
                        <path
                            fill-rule="evenodd"
                            fill="rgb(255, 255, 255)"
                            d="M13.711,12.337 L23.103,21.728 C23.622,22.247 23.622,23.088 23.103,23.607 C22.584,24.125 21.744,24.125 21.225,23.607 L11.833,14.215 L2.441,23.607 C1.923,24.125 1.082,24.125 0.563,23.607 C0.044,23.088 0.044,22.247 0.563,21.728 L9.955,12.337 L0.563,2.945 C0.044,2.426 0.044,1.585 0.563,1.066 C1.082,0.547 1.923,0.547 2.441,1.066 L11.833,10.458 L21.225,1.066 C21.744,0.547 22.584,0.547 23.103,1.066 C23.622,1.585 23.622,2.426 23.103,2.945 L13.711,12.337 Z"
                        ></path>
                    </svg>
                </a>
            </div>
        </div> -->
        <div class="container">
            <div class="login-modal__body">
                <div class="login--body__left">
                    <h1 v-html="loginTitle"></h1>
                    <p v-html="loginDescription"></p>
                </div>
                <div class="login--body__right">
                    <!-- <h1 v-if="loginModal">Login</h1> -->
                    <!-- <h1 v-if="!loginModal">Forgot Password</h1> -->
                    <base-form ref="loginForm" @submit="submit">
                        <div class="form-group">
                            <div class="bv-no-focus-ring">
                                <label class="control-label">{{
                                    $t('Email address')
                                }}</label>
                                <div class="input-data">
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="userProfile.email"
                                        rules="required"
                                    />
                                    <!-- :placeholder="$t('Enter your Email')" -->
                                </div>
                                <svg
                                    version="1.1"
                                    id="Capa_1"
                                    x="0px"
                                    y="0px"
                                    fill="#fff"
                                    viewBox="0 0 512 512"
                                    style="enable-background:new 0 0 512 512;"
                                    xml:space="preserve"
                                >
                                    <g>
                                        <g>
                                            <path
                                                d="M467,80.609H45c-24.813,0-45,20.187-45,45v260.782c0,24.813,20.187,45,45,45h422c24.813,0,45-20.187,45-45V125.609
                                        C512,100.796,491.813,80.609,467,80.609z M461.127,110.609l-6.006,5.001L273.854,266.551c-10.346,8.614-25.364,8.614-35.708,0
                                        L56.879,115.61l-6.006-5.001H461.127z M30,132.267L177.692,255.25L30,353.543V132.267z M467,401.391H45
                                        c-7.248,0-13.31-5.168-14.699-12.011l171.445-114.101l17.204,14.326c10.734,8.938,23.893,13.407,37.051,13.407
                                        c13.158,0,26.316-4.469,37.051-13.407l17.204-14.326l171.444,114.1C480.31,396.224,474.248,401.391,467,401.391z M482,353.543
                                        l-147.692-98.292L482,132.267V353.543z"
                                            ></path>
                                        </g>
                                    </g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                </svg>
                            </div>
                        </div>
                        <div v-if="loginModal" class="form-group">
                            <div class="bv-no-focus-ring">
                                <label class="control-label">{{
                                    $t('Password')
                                }}</label>
                                <div class="input-data">
                                    <input
                                        type="password"
                                        class="form-control"
                                        v-model="userProfile.password"
                                        rules="required"
                                    />
                                    <!-- :placeholder="$t('Enter your Password')" -->
                                </div>
                                <svg
                                    version="1.1"
                                    id="Capa_1"
                                    x="0px"
                                    y="0px"
                                    fill="#fff"
                                    viewBox="0 0 512 512"
                                    style="enable-background:new 0 0 512 512;"
                                    xml:space="preserve"
                                >
                                    <g>
                                        <g>
                                            <g>
                                                <path
                                                    d="M102.385,186.667c-2.958-5.104-9.51-6.854-14.573-3.906l-2.479,1.431v-2.858c0-5.896-4.771-10.667-10.667-10.667
                                            c-5.896,0-10.667,4.771-10.667,10.667v2.858l-2.479-1.431c-5.094-2.948-11.625-1.198-14.573,3.906
                                            c-2.948,5.104-1.198,11.625,3.906,14.573l2.473,1.427l-2.473,1.427c-5.104,2.948-6.854,9.469-3.906,14.573
                                            c1.979,3.417,5.563,5.333,9.25,5.333c1.813,0,3.646-0.458,5.323-1.427L64,221.142V224c0,5.896,4.771,10.667,10.667,10.667
                                            c5.896,0,10.667-4.771,10.667-10.667v-2.858l2.479,1.431c1.677,0.969,3.51,1.427,5.323,1.427c3.688,0,7.271-1.917,9.25-5.333
                                            c2.948-5.104,1.198-11.625-3.906-14.573l-2.473-1.427l2.473-1.427C103.583,198.292,105.333,191.771,102.385,186.667z"
                                                ></path>
                                                <path
                                                    d="M187.719,186.667c-2.969-5.104-9.5-6.854-14.573-3.906l-2.479,1.431v-2.858c0-5.896-4.771-10.667-10.667-10.667
                                            s-10.667,4.771-10.667,10.667v2.858l-2.479-1.431c-5.115-2.948-11.635-1.198-14.573,3.906
                                            c-2.948,5.104-1.198,11.625,3.906,14.573l2.473,1.427l-2.473,1.427c-5.104,2.948-6.854,9.469-3.906,14.573
                                            c1.979,3.417,5.563,5.333,9.25,5.333c1.813,0,3.646-0.458,5.323-1.427l2.479-1.431V224c0,5.896,4.771,10.667,10.667,10.667
                                            s10.667-4.771,10.667-10.667v-2.858l2.479,1.431c1.677,0.969,3.51,1.427,5.323,1.427c3.688,0,7.271-1.917,9.25-5.333
                                            c2.948-5.104,1.198-11.625-3.906-14.573l-2.473-1.427l2.473-1.427C188.917,198.292,190.667,191.771,187.719,186.667z"
                                                ></path>
                                                <path
                                                    d="M273.052,186.667c-2.958-5.104-9.49-6.854-14.573-3.906L256,184.191v-2.858c0-5.896-4.771-10.667-10.667-10.667
                                            c-5.896,0-10.667,4.771-10.667,10.667v2.858l-2.479-1.431c-5.104-2.948-11.635-1.198-14.573,3.906
                                            c-2.948,5.104-1.198,11.625,3.906,14.573l2.473,1.427l-2.473,1.427c-5.104,2.948-6.854,9.469-3.906,14.573
                                            c1.979,3.417,5.563,5.333,9.25,5.333c1.813,0,3.646-0.458,5.323-1.427l2.479-1.431V224c0,5.896,4.771,10.667,10.667,10.667
                                            c5.896,0,10.667-4.771,10.667-10.667v-2.858l2.479,1.431c1.677,0.969,3.51,1.427,5.323,1.427c3.688,0,7.271-1.917,9.25-5.333
                                            c2.948-5.104,1.198-11.625-3.906-14.573l-2.473-1.427l2.473-1.427C274.25,198.292,276,191.771,273.052,186.667z"
                                                ></path>
                                                <path
                                                    d="M490.667,256v-10.667c0-20.266-8.178-38.622-21.333-52.09v-43.91c0-23.531-19.135-42.667-42.667-42.667h-384
                                            C19.135,106.667,0,125.802,0,149.333V256c0,23.531,19.135,42.667,42.667,42.667H320V384c0,11.76,9.573,21.333,21.333,21.333
                                            h149.333c11.76,0,21.333-9.573,21.333-21.333V277.333C512,265.573,502.427,256,490.667,256z M469.333,245.333V256H362.667
                                            v-10.667C362.667,215.927,386.594,192,416,192S469.333,215.927,469.333,245.333z M320,277.333H42.667
                                            c-11.76,0-21.333-9.573-21.333-21.333V149.333c0-11.76,9.573-21.333,21.333-21.333h384c11.76,0,21.333,9.573,21.333,21.333
                                            v28.823c-9.737-4.661-20.505-7.49-32-7.49c-25.81,0-48.589,13.18-62.004,33.148l-1.99-1.148l2.473-1.427
                                            c5.104-2.948,6.854-9.469,3.906-14.573c-2.958-5.104-9.51-6.854-14.573-3.906l-2.479,1.431v-2.858
                                            c0-5.896-4.771-10.667-10.667-10.667c-5.896,0-10.667,4.771-10.667,10.667v2.858l-2.479-1.431
                                            c-5.094-2.948-11.625-1.198-14.573,3.906c-2.948,5.104-1.198,11.625,3.906,14.573l2.473,1.427l-2.473,1.427
                                            c-5.104,2.948-6.854,9.469-3.906,14.573c1.979,3.417,5.563,5.333,9.25,5.333c1.813,0,3.646-0.458,5.323-1.427l2.479-1.431V224
                                            c0,5.896,4.771,10.667,10.667,10.667c5.896,0,10.667-4.771,10.667-10.667v-2.858l2.479,1.431
                                            c0.421,0.243,0.897,0.178,1.335,0.358c-2.26,7.117-3.814,14.547-3.814,22.402V256C329.573,256,320,265.573,320,277.333z
                                            M490.667,384H341.333V277.333h149.333V384z"
                                                ></path>
                                                <path
                                                    d="M405.333,338.053V352c0,5.896,4.771,10.667,10.667,10.667c5.896,0,10.667-4.771,10.667-10.667v-13.947
                                            c6.258-3.724,10.667-10.262,10.667-18.053c0-11.76-9.573-21.333-21.333-21.333c-11.76,0-21.333,9.573-21.333,21.333
                                            C394.667,327.792,399.076,334.329,405.333,338.053z"
                                                ></path>
                                            </g>
                                        </g>
                                    </g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                    <g></g>
                                </svg>
                            </div>
                        </div>
                        <div class="button-row">
                            <button class="submitBtn" type="submit">
                                {{
                                    loginModal
                                        ? $t('Login')
                                        : $t('Reset Password')
                                }}
                            </button>
                        </div>
                        <div v-if="fieldsRequiredError" class="fields-error">
                            Please fill all fields.
                        </div>
                    </base-form>
                    <div
                        v-if="loginModal"
                        class="forgot-pass"
                        href=""
                        @click="loginModal = false"
                    >
                        {{ $t('Forgot Password?') }}
                    </div>
                    <div
                        v-else
                        class="forgot-pass"
                        href=""
                        @click="loginModal = true"
                    >
                        {{ $t('Back to Login') }}
                    </div>
                    <router-link
                        ref="signUpButton"
                        :to="{
                            name: 'signup',
                            params: {
                                lang: $i18n.locale,
                            },
                        }"
                        class="sign-up"
                    >
                        {{ $t('Signup for the first time? Register now!') }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ActivitiesHelper } from '@/common/crud-helpers/activities'
import { SettingsHelper } from '@/common/crud-helpers/settings'
import { UsersHelper } from '@/common/crud-helpers/users'
import { API_BASE } from '@/conf'
import { Notify } from 'notiflix'
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            userProfile: {
                email: '',
                password: '',
            },
            banner: '',
            fieldsRequiredError: false,
            loginModal: true,
            loginTitle: 'Welcome to Zainiac!',
            loginDescription: `An online ideas platform created for Zainers by Zainers.
                        Zainiac is a space where ideas are shared, communities
                        are formed and our wonderful world is filled with the
                        ability to connect, collaborate and perform. We are
                        looking for new innovative ideas that will take on the
                        digital world. But, most importantly we are looking for
                        people like YOU. People who have passion to make a
                        change and disrupt the digital world. We believe in our
                        employees and Zainiac is ready to help transform your
                        ideas into plans...`,
        }
    },
    computed: {
        ...mapGetters(['userAuth', 'dashboardSettings']),
    },
    async created() {
        if (!this.userAuth || !this.userAuth.id) {
            ActivitiesHelper.checkUserLogin({})
                .then(resp => {
                    if (resp && resp.access && resp.refresh) {
                        this.goToNextRoute()
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
        if (!this.dashboardSettings || !this.dashboardSettings.login) {
            await SettingsHelper.getDashboardSettings()
        } else if (this.dashboardSettings && this.dashboardSettings.login) {
            this.setLoginBannerAndText()
        }
    },
    watch: {
        dashboardSettings() {
            this.setLoginBannerAndText()
        },
    },
    methods: {
        setLoginBannerAndText() {
            this.banner = this.dashboardSettings.login.background_img
            this.loginTitle = this.dashboardSettings.login[
                `title_${this.$i18n.locale}`
            ]
            this.loginDescription = this.dashboardSettings.login[
                `description_${this.$i18n.locale}`
            ]
        },
        submit() {
            if (
                this.loginModal &&
                (!this.userProfile.email || !this.userProfile.password)
            ) {
                this.fieldsRequiredError = true
                return
            } else if (!this.loginModal && !this.userProfile.email) {
                this.fieldsRequiredError = true
                return
            } else this.fieldsRequiredError = false

            let userData = {}
            userData.email = this.userProfile.email
            if (this.loginModal) userData.password = this.userProfile.password

            if (this.loginModal) {
                UsersHelper.loginUser({ data: userData })
                    .then(() => {
                        this.goToNextRoute()
                    })
                    .catch(err => {
                        Notify.Failure(
                            err.detail
                                ? err.detail
                                : err.email.length
                                ? err.email[0]
                                : 'An error occurs while trying to login',
                        )
                    })
            } else {
                UsersHelper.resetPassword({ data: userData })
                    .then(() => {
                        Notify.Success('Reset password email has been sent')
                    })
                    .catch(err => {
                        Notify.Failure(
                            err.email
                                ? err.email
                                : 'An error occurs while trying to reset password',
                        )
                    })
            }
        },
        goToNextRoute() {
            const prev = this.$route.query.prev
            const next = this.$route.query.next
            const queryParams = this.$route.query.queryParams
            if (next) {
                ActivitiesHelper.cmsRedirectUrl({
                    API_BASE: API_BASE,
                    lang: this.$i18n.locale,
                    url: API_BASE + next,
                })
                    .then(resp => {
                        window.location.href = JSON.parse(
                            resp.request.response,
                        ).url
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                this.$router.push({
                    name: prev ? prev : 'profile',
                    params: queryParams ? JSON.parse(queryParams) : {},
                })
            }
        },
    },
}
</script>
<style lang="scss">
.form-group {
    .control-label {
        font-size: 1.375rem;
        font-weight: 700;
        display: inline-block;
        margin-bottom: 0px;
        color: #009b74;
        padding: 0 0 1rem 1.375rem;
        text-transform: none;
    }
}
.form-control {
    font-size: 1.125rem;
    background: #fff;
    outline: none;
    border: solid 2px transparent;
    border-radius: 0;
    display: block;
    width: 100%;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
// .login-modal__close {
// top: 0;
// visibility: visible;
// }
.login-modal {
    @media screen and (max-width: 1199px) {
        padding: 1.875rem;
    }
    --modal-height: 100vh;
    position: relative;
    // left: 0;
    // right: 0;
    // margin: auto;
    // width: 85%;
    // z-index: 50;
    padding: 3.75rem;
    // background: #009b74;
    // background: linear-gradient(97deg, #342472 0%, #7f2189 100%);
    transition: 0.4s;
    min-height: calc(var(--modal-height) - 170px);
    // overflow-y: auto;
    .login-banner {
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        &::after {
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            content: '';
            z-index: 0;
            top: 0px;
            background: var(--primary);
            opacity: 0.1;
        }
        & > img {
            object-fit: cover;
            height: 100%;
        }
        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            background: #000;
            opacity: 0.6;
            z-index: 0;
            left: 0;
            right: 0;
            top: 0;
        }
        .custom-banner {
            width: 100%;
        }
    }
    .container {
        position: relative;
    }
    &--head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        #arabic {
            font-size: 1.875rem;
            color: #b2b3b6;
            padding: 3.75rem;
            @media screen and (max-width: 767px) {
                font-size: 1.25rem;
                padding: 0 0 0 1.25rem;
            }
        }
        a img {
            @media screen and (max-width: 767px) {
                max-width: 125px;
            }
        }
        .login-modal--close {
            cursor: pointer;
            a {
                font-size: 1rem;
                color: #fff !important;
                svg {
                    width: 1.5625rem;
                    height: auto;
                    margin-left: 20px;
                    @media screen and (max-width: 991px) {
                        width: 25px;
                    }
                }
            }
        }
    }
    .login-modal__body {
        display: flex;
        // padding: 10.625rem 6.25rem 5.625rem 1.875rem;
        padding: 10.625rem 0 0 0;
        padding-bottom: 0;
        // @media screen and (max-width: 1700px) {
        //     padding-right: 2.5rem;
        // }
        @media screen and (max-width: 767px) {
            flex-direction: column;
            padding: 1.25rem;
        }
        .login--body__left {
            @media screen and (max-width: 767px) {
                max-width: 100%;
                flex: 0 0 100%;
                padding: 2.5rem 0 0 0;
            }
            max-width: 50%;
            flex: 0 0 50%;
            padding: 0 3.125rem 0 0;
            h1 {
                color: var(--white);
                font-size: 1.75rem;
                font-weight: 600;
                margin: 0;
                line-height: 1;
            }
            @media screen and (max-width: 1300px) {
                h1 {
                    font-size: 2.25rem;
                }
            }
            @media screen and (max-width: 991px) {
                h1 {
                    font-size: 1.875rem;
                }
            }
            @media screen and (max-width: 414px) {
                h1 {
                    font-size: 1.5625rem;
                }
            }
            p {
                color: #fff;
                font-size: 1.375rem;
                padding-top: 3.375rem;
                line-height: 1.5;
                @media screen and (max-width: 767px) {
                    padding-top: 1.25rem;
                    font-size: 1.125rem;
                }
            }
        }
        .login--body__right {
            max-width: 50%;
            flex: 0 0 50%;
            padding-left: 15.625rem;
            @media screen and (max-width: 767px) {
                max-width: 100%;
                flex: 0 0 100%;
                padding: 2.5rem 0 0 0 !important;
            }
            @media screen and (max-width: 1700px) {
                padding-left: 6.25rem;
            }
            @media screen and (max-width: 1199px) {
                padding-left: 3.75rem;
            }
            h1 {
                font-size: 1.75rem;
                font-weight: 600;
                color: #fff;
                text-align: right;
                margin-bottom: 1.5625rem;
                line-height: 1;
            }
            @media screen and (max-width: 1300px) {
                h1 {
                    font-size: 2.25rem;
                }
            }
            @media screen and (max-width: 991px) {
                h1 {
                    font-size: 1.875rem;
                }
            }

            @media screen and (max-width: 414px) {
                h1 {
                    font-size: 1.5625rem;
                }
            }
            .form-group {
                margin-bottom: 3.75rem;
                .bv-no-focus-ring {
                    position: relative;
                    .control-label {
                        color: #fff;
                        font-size: 1.375rem;
                        font-weight: bold;
                        padding: 0 0 1.25rem 1.5625rem;
                    }
                    .input-data {
                        position: relative;
                        ::placeholder {
                            color: var(--dark);
                        }
                        &::before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 2px;
                            height: 8px;
                            background-color: #fff;
                        }
                        &::after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 2px;
                            height: 8px;
                            background-color: #fff;
                        }
                        .form-control {
                            font-size: 1.125rem;
                            background-color: transparent;
                            box-shadow: none;
                            border-top: 2px solid #fff;
                            border-bottom: 2px solid #fff;
                            border-right: 2px solid #fff;
                            border-left: 0px;
                            height: 3.75rem;
                            padding: 0 0 0 1.5625rem;
                            color: #fff;
                        }
                    }
                    svg {
                        width: 35px;
                        height: auto;
                        position: absolute;
                        top: 53%;
                        left: -20px;
                    }
                }
            }
            .button-row {
                padding-top: 0.9375rem;
            }
            button {
                padding: 1.4375rem 6.25rem;
                border: none;
                color: var(--primary);
                font-size: 1.5rem;
                font-weight: 700;
                text-align: center;
                background-color: #fff;
                margin: auto;
                transition: 0.4s ease;
                text-transform: uppercase;
            }
            .forgot-pass {
                cursor: pointer;
                color: var(--white);
                font-size: 1.25rem;
                margin: 1.75rem 1.875rem 0 1.875rem;
                font-weight: 300;
                display: block;
                text-align: center;
            }
            .sign-up {
                color: var(--secondary);
                font-size: 1.15rem;
                margin: 0.75rem 1.875rem 0 1.875rem;
                font-weight: 700;
                display: block;
                text-align: center;
            }
            .fields-error {
                color: red;
                font-size: 1rem;
                margin: 0.75rem 1.875rem 0 1.875rem;
                font-weight: 500;
                display: block;
                text-align: center;
            }
        }
    }
    font-size: 1rem;
    color: var(--default);
    line-height: 1;
}

.ar {
    .login-modal .login-modal__body .login--body__right {
        padding-left: 1.5rem;
        padding-right: 15.625rem;
        @media screen and (max-width: 1199px) {
            padding-right: 3.75rem;
        }
        @media screen and (max-width: 1700px) {
            padding-right: 6.25rem;
        }
    }
    .login-modal .login-modal__body .login--body__left {
        padding: 0 0 0 3.125rem;
        @media screen and (max-width: 767px) {
            padding: 2.5rem 0 0 0;
        }
    }
}
</style>

<style scoped>
@media (max-width: 768px) {
    /* .submitBtn {
        display: none !important;
    } */

    .login-modal .login-modal__body .login--body__left h1 {
        padding-top: 4.5rem;
    }
}
</style>
